export default Vue.extend({ template: `

<template>
  <span> x33xx <input type="checkbox" @click="checkedHandler($event)" :checked="params.value" /></span>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import Vue from "vue";
// alert(3);

export default {
  // setup() {},

  methods: {
    checkedHandler(event) {
      if (!this.params) return false;
      let checked = event.target.checked;
      let colId = this.params.column.colId;
      this.params.node.setDataValue(colId, checked);
    },
  },
};
</script>
