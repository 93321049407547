<template>
  <div>
    <titleBar
      :title="'Integration'"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      :isChild="true"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800" style="max-width: 900px">
        <br />
        <br />
        <h2 class="title is-4">Add conversation examples</h2>
        <p>
          These will be use to train your AI chatbot. This feature is complementary to other chat settings. It
          introduces some complexity to your bot, but may be a good way to provide training data in cases where you've
          reached limit with prompt configuration.
        </p>

        <ag-grid-vue
          class="ag-theme-alpine"
          :columnDefs="columnDefs"
          @grid-ready="onGridReady"
          :defaultColDef="defaultColDef"
          :enableRangeSelection="true"
          :copyHeadersToClipboard="false"
          :rowData="blueprint.chatExamples"
          :enableFillHandle="true"
          :undoRedoCellEditing="true"
          :undoRedoCellEditingLimit="true"
          :enableCellChangeFlash="true"
          domLayout="autoHeight"
          :components="{
            //   checkboxRenderer: CheckboxCellRenderer
          }"
        ></ag-grid-vue>

        <b-button
          label="Save changes"
          @click="saveChatExamples"
          :loading="saving"
          icon-pack="fas"
          icon-left="save"
          type="is-dark"
        />
        <!-- 
        <b-button
          label="Add 1 empty row"
          @click="addRow(1)"
          icon-pack="fal"
          pack="fal"
          icon-left="plus"
          type="is-lighNNt"
        />
 -->
        <b-button
          label="Add empty rows"
          @click="addRow(10)"
          icon-pack="fal"
          pack="fal"
          icon-left="plus"
          type="is-lighNNt"
        />

        <hr />

        <br />
        <br />
        <h2 class="title is-4">Fine-tuning</h2>
        <p></p>

        <hr />
        <headerManage
          title="Fine-tuned chat engine"
          sub="Not set "
          desc="Train a custom chat model on your examples. This will take a few minutes."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <b-button
              label="Run chat engine training"
              @click="saveChatExamples"
              :loading="saving"
              icon-pack="fas"
              icon-left="save"
              type="is-dark"
            />
            <OtherComponent />
          </div>
        </headerManage>

        <!-- 
        <b-field horizontal label="discordBotChannelId">
          <b-input
            size="is-"
            placeholder="123456789"
            v-model="bot.discordBotChannelId"
            :loading="posting"
            :disabled="posting"
            type="text"
          ></b-input>
        </b-field> -->

        <!-- END     
    -->
      </div>
    </div>
  </div>
</template>

<script>
//import Editor from "@/components/e/EditorV";
import headerManage from "@/components/studio/headerManage";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
//npm i @ag-grid-community/client-side-row-model @ag-grid-community/core @ag-grid-community/styles
//npm i @ag-grid-community/vue @ag-grid-enterprise/clipboard @ag-grid-enterprise/menu @ag-grid-enterprise/range-selection
import { AgGridVue } from "@ag-grid-community/vue";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";

//my custom component for checkbox:

//import CheckboxCellRenderer from "@/plugins/ag-grid-checkbox-cell-renderer.js";
import CheckboxCellRenderer from "@/plugins/ag-grid-checkbox-cell-renderer";
//https://plnkr.co/edit/KIlOopnaJUrg0c7A?preview
// https://stackoverflow.com/questions/41706848/how-to-use-a-checkbox-for-a-boolean-data-with-ag-grid

const ideaFields = "operationId desc path method".split(" ");
const columnDefs = [
  { field: "userMessage", minWidth: 150 }, //pinned: "left"
  {
    field: "botResponse",
    minWidth: 300,
    editable: true,
    cellEditor: "agLargeTextCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      maxLength: "3000", // override the editor defaults
      cols: "50",
      // rows: "6",
    },
  },
  //   { field: "method" },
  /*
  {
    field: "method",
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["GET", "POST", "PUT", "DELETE", "PATCH"],
    },
    // ...other props
  },

  { field: "path", minWidth: 200 },
  // { field: "desc" },
  { field: "emoji", minWidth: 80 },
  {
    field: "desc",
    minWidth: 300,
    editable: true,
    cellEditor: "agLargeTextCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      maxLength: "3000", // override the editor defaults
      cols: "50",
      rows: "6",
    },
  },
  //  { field: "responseType", minWidth: 150 },
  {
    field: "responseType",
    minWidth: 120,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: "Array Object String Number Boolean File Redirect".split(" "),
    },
    // ...other props
  },

  { field: "loggedRequired", minWidth: 150, cellRenderer: "checkboxRenderer" },
  // cellRenderer: "checkboxRenderer" - not working...

  // checkboxSelection: true to select entire row
  { field: "ownerRequired" },
  { field: "adminRequired" },

  { field: "maxAgeClient" },
  { field: "maxAgeServer" },
  { field: "maxAgeCdn" },
  { field: "staleAgeClient" },
  { field: "staleAgeServer" },
  { field: "staleAgeCdn" },

  { field: "timeout" },
  { field: "monitoring" },
  { field: "npm" },
  // { field: "devNotes" },

  {
    field: "devNotes",
    editable: true,
    cellEditor: "agLargeTextCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      maxLength: "900", // override the editor defaults
      cols: "50",
      rows: "6",
    },
  },*/
];

var columnDefsIdeas = columnDefs.filter((i) => ideaFields.includes(i.field));

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  // MenuModule, //pro
  RangeSelectionModule, //pro
  ClipboardModule,
]);

export default {
  //name: "readmeEditor",
  components: {
    //Editor,
    headerManage,
    "ag-grid-vue": AgGridVue,
    //  CheckboxCellRenderer,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    blueprint: {
      type: Object,
    },
    blueprints: {
      type: Array,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columnDefs,
      defaultColDef: {
        editable: true,
        flex: 1,
        minWidth: 100,
        resizable: true,
      },
      saving: false,
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  methods: {
    save() {
      this.$emit("save");
    },
    addRow(nb) {
      for (let i = 0; i < nb; i++) {
        this.blueprint.chatExamples.push({});
      }
    },
    saveChatExamples() {
      this.saving = true;
      this.$emit("save", this.blueprint);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      const updateData = (data) => params.api.setRowData(data);
      /*
      fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
        .then((resp) => resp.json())
        .then((data) => updateData(data));*/
    },
  },
};
</script>

<style></style>
